import styled, { css } from 'styled-components'

import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { colors } from '../../constants/colors'
import { Presets, Type, TypeProps } from '../../elements/Typography/Typography'

export type DisclaimerProps = {
  children?: React.ReactNode
  color?: string
  textColor?: string
  preset?: Presets
  fixedFontSize?: number
} & TypeProps

const _Disclaimer = styled.div`
  position: relative;
  padding-left: ${({ theme }) => theme.spacing[1]};
  ::after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 2px;
    background: linear-gradient(
      90deg,
      ${colors.lagoonBlue} 0%,
      ${colors.fountainBlue} 97.99%
    );

    ${({ color }) =>
      color &&
      css`
        background: ${color};
      `}
  }

  a {
    color: var(--color-link);
  }
`

export const Disclaimer = ({
  children,
  color,
  textColor,
  fixedFontSize,
  preset = 'labelSmall',
  ...rest
}: DisclaimerProps) =>
  children ? (
    <_Disclaimer color={color}>
      {typeof children === 'string' ? (
        <Type
          preset={preset}
          multiline
          color={textColor}
          style={{fontSize: fixedFontSize}}
          dangerouslySetInnerHTML={{
            __html: formatHtmlText(children),
          }}
          {...rest}
        />
      ) : (
        children
      )}
    </_Disclaimer>
  ) : null
